.center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card-set-up{
    
    background-color: rgb(255, 255, 255,0.6);
     border:2px solid white;
      margin-top: 3rem;
      border-radius: 14px;
      padding: 1rem 1.5rem;
      backdrop-filter: blur(4px);

    }

 .card-width-200{
  width: 300px;
  height: 300px;

    }
    .card-width-300{
      width:350px;
      height: 510px;
    }
    .card-height-200{
      width:350px;
      height:400px;
    }

    .text-center{
      text-align: center;
    }

    .qr-image{
      width: 180px;
      height:180px;
    }

  .qr-center{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    top: 45px;

    
  }



  /* Terms */

.my-1{
  margin: 10px 0px 10px 0px;
}
.my-2{
  margin: 20px 0px 20px 0px;
}

.my-3{
  margin: 30px 0px 30px 0px;
}

.mx-1{
  margin:0px 10px 0px 10px;
}
.mx-2{
  margin: 0px 20px 0px 20px;
}
.mx-3{
  margin: 0px 30px 0px 30px;
}

  .terms-conditions p{
    font-size: 14px;
    font-weight: bold;
  }
   

  .checkbox{
    width: 25px;
    height: 25px;
    border: 2px solid black;
  }

  .d-flex{
    display: flex;
  }
  .d-flex p {
    font-size: 14px;
    font-weight: bold;
  }


.text-white{
  color: white;
}