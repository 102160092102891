.dashboard-container {
  padding: 0 3rem;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.page-title {
  width: 34%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 0.2rem 0.4rem;
  color: #023545;
}
/* .title-logo{
  font-size: 20px;
} */

.page-title span {
  font-size: 27px;
  margin-top: 5px;
}

.open-locker {
  background-color: rgb(255, 255, 255, 0.6);
  width: 600px;
  height: 300px;
  opacity: 0.9;
  margin-top: 3rem;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  backdrop-filter: blur(4px);
  overflow-y: scroll;
}
.open-locker::-webkit-scrollbar {
  display: none;
}
.location-setup {
  background-color: rgb(255, 255, 255, 0.6);
  width: 400px;
  height: 300px;
  opacity: 0.9;
  margin-top: 2rem;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  backdrop-filter: blur(4px);
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-setup h3,
.open-locker h3 {
  font-weight: bolder;
  color: #023545;
}

.location-setup li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
.location-setup ul {
  margin: 2rem 0;
}

.edit-iocn {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.2rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

.bolder-font {
  font-weight: bolder;
}

.open-locker li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.open-locker li {
  margin-top: 0.5rem;
}

.card-header {
  font-weight: bolder;
  color: #023545;
}
.pie_chart_status_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin: auto;
}
