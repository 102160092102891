.locker-set-up {
  background-color: rgb(255, 255, 255, 0.6);
  /* width: 900px; */
  min-height: 300px;
  /* opacity: 0.9; */
  margin-top: 3rem;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  backdrop-filter: blur(4px);
}

.headline {
  display: flex;
}

.locker-set-up h3 {
  font-weight: bold;
}

.card-head {
  display: flex;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: 2rem;
  flex-wrap: wrap;
  margin-top: 20px;
  /* max-width: 500px !important; */
}

.locker-card {
  width: 133px;
  height: 138px;
  border-radius: 10px;
  background-color: #e7f3f5;
  /* background-color: whitesmoke; */
  opacity: 100;
}

.locker-card-1 {
  width: 208px;
  border-radius: 10px;
  height: 250px;
  background-color: #e7f3f5;
  /* background-color: whitesmoke; */
  opacity: 100;
  padding: 1rem 1rem;
}

.locker-card-1 input {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  margin: 20px 0;
}

.locker-card-2 {
  width: 258px;
  border-radius: 10px;
  height: auto;
  background-color: #e7f3f5;
  /* background-color: whitesmoke; */
  opacity: 100;
  padding: 1rem 1rem;
}

.locker-card-2 input {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  margin: 9px 0;
}

.locker-card-2 select {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  margin: 9px 0;
}

.locker-icon {
  /* font-size: 54px; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.locker-svg {
  height: 65px;
  width: 65px;
}

.locker-card-buttons-head {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  margin: 8px 8px 0px 0px;
}

.locker-card-buttons {
  width: 55px !important;
  height: 25px !important;
  background-color: #e7f3f5;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  /* margin: 0px 8px 0px 0px; */
  font-size: 12px;
}

.Edit_locations_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: flex-start;
}

.Location_edit_details {
  background-color: rgb(255, 255, 255, 0.6);
  margin-top: 3rem;
  min-height: 300px;
  border-radius: 14px;
  padding: 1rem 1.5rem;
  backdrop-filter: blur(4px);
}

.Location_edit_details {
  display: flex;
  align-items: center;
  justify-content: center;
}