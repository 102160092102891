.input-withlable-container {
  width: 80%;
  position: relative;
}

.input-withlable-container input {
  width: 100%;
  margin-bottom: 0.4rem;
  padding-left: 25px;
  background-size: 20px;
  background-color: #292929;
  outline: none;
  box-shadow: none;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 0.5rem;
}

.icon {
  position: absolute;
  top: 87%;
  left: 6%;
  transform: translate(-50%, -90%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #959595;
}

.input-withlable-container span {
  margin: 0.3rem;
  display: inline-block;
  font-size: 14px;
  color: #ffffffbd;
}

.create-btn {
  background-color: #4a6ccc;
  -webkit-box-shadow: 0px 0px 19px 4px rgba(30, 43, 82, 1);
  -moz-box-shadow: 0px 0px 19px 4px rgba(30, 43, 82, 1);
  box-shadow: 0px 0px 19px 4px rgba(30, 43, 82, 1);
  border-radius: 12px;
  color: #fff;
  font-weight: 900;
  width: 35%;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
}
