.locker-select {
  padding: 0.6rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 20px;
}

.lockertype {
  position: absolute;
  font-size: 15px;
  background-color: #201f1e;
  color: white;
  width: 20px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: inset 0 0 2px 1px #00000052;
  border: 1px solid #3979b5;
}
