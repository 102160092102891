.report-card-head{
   margin: 13px 50px 0px 0px;
   
}

.d-flex{
   display: flex;
   gap: 15px;
   justify-content: center;
}

.table-head{
   margin-top: 30px;
}