@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

a{
  text-decoration: none;
  color: #023545;
}

button{
  padding: 1rem 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  /*  */

  /* padding: 0.8rem 1.5rem; */
    border-radius: 14px;
    box-shadow: 0 0 5px 1px #c3c1d0;
    background-color: #C3C1D0;
}

img{
  height: 100%;
  width: 100%;
}

li{
  text-decoration: none;
  list-style: none;
}