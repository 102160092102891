input {
  width: 100%;
  /* margin-bottom: .8rem; */
  padding-left: 25px;
  background-size: 20px;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1rem 3rem;
  color: rgb(0, 0, 0);
  border-radius: .5rem;
  -webkit-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  -moz-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  position: relative;
}
.selecttag {
  width: 85%;
  /* margin-bottom: .8rem; */
  padding-left: 25px;
  background-size: 20px;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1rem 3rem;
  color: rgb(0, 0, 0);
  border-radius: .5rem;
  -webkit-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  -moz-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  position: relative;
}

select {
  width: 100%;
  /* margin-bottom: .8rem; */
  padding-left: 25px;
  background-size: 20px;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1rem 3rem;
  color: rgb(0, 0, 0);
  border-radius: .5rem;
  -webkit-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  -moz-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  position: relative;
}

.input-container {
  width: 35%;
  position: relative;
}

.icon {
  /* z-index: 09; */
  position: absolute;
  /* top: 100%; */
  /* left: 50%; */
  /* transform: translate(-50%,-50%); */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  color: #959595;
  width: 100%;
  text-align: left;
  height: 100%;
  padding: 1rem;
}

.inputWidth-30 {
  width: 30%;
}

.inputWidth-20 {
  width: 157px !important;
  height: 44px !important;
  background-color: #E7F3F5 !important;
}

.inputWidth-25 {
  width: 120px !important;
  height: 44px !important;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 0 5px 1px #c3c1d0 !important;
  background-color: #C3C1D0 !important;
  padding: 1rem !important;


}

.input-container-select {
  /* width: 30%; */
  /* height:50px; */
  position: relative;
  border: none;
  background-color: #ffffff;
  outline: none;
  border: none;
  padding: 1rem 3rem;
  color: rgb(0, 0, 0);
  border-radius: .5rem;
  -webkit-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  -moz-box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  box-shadow: inset 0px 0px 19px 4px rgb(223, 223, 223);
  font-size: 12.5px;
}

select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  width: 50%;
}