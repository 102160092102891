.main-container{
  display: flex;
  background: url('./assests/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.menu-container{
  margin-top: 1rem;
}

.text-center{
  text-align: center;
}


