
.side-bar-container{
    background-color: rgb(255, 255, 255,0.6);
    color: #023545;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-height: 100vh;
    /* -webkit-box-shadow: inset -8px 1px 21px -4px rgba(16,16,16,1);
    -moz-box-shadow: inset -8px 1px 21px -4px rgba(16,16,16,1);
    box-shadow: inset -8px 1px 21px -4px rgba(16,16,16,1); */
    border: 2px solid #fff;
    backdrop-filter: blur(4px);
}

.selected .menu-name{
    color: #3492FF;
    /* border-bottom: 2px solid; */
    text-decoration: underline;
    font-weight: 700;
    width: 100%;
    /* background-color: red; */
}
.selected {
    color: #3492FF;   
}
.nav-link{
    padding: 1rem;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.unselected{
    color: #fff;
}

.menu-name{
    padding-left: .4rem;
}

.sign-btn{
    background-color: #023545;
    color: #fff;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px #023545;
}

.logo-container{
    /* height: 169px;
    width: 235px; */
    /* box-shadow: -1px 17px 22px -4px #101010; */
    /* background: red;
    border-radius: 15px;
    background-color: #023545; 
    box-shadow: 0 0 5px 1px #023545; */
    height: 55px;
    width: 182px;
    border-radius: 15px;

}

.sidebar-menu-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;

}

.width100per{
    width: 100%;
}




