.login-wrapper {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url("../assests/login.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: rgb(6 6 6 / 70%);
  background-blend-mode: soft-light;
  /*  */
  position: relative;
}
.login-container {
  width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background-color: lightgray;
  border-radius: 10px;
}
.login-container img {
  width: 60%;
}
.Login_input input {
  margin-bottom: 10px;
  margin-top: 5px;
}
.login-container p:hover {
  cursor: pointer;
}
.login-container {
  margin-top: 20px;
}
